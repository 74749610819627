.h_wrapper{
    color:white;


}

.hero-container {
    justify-content: space-around;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.Left_section{
gap: 1.2rem;

}
.p_intro{
    margin-top: 1rem;
    color: rgb(175, 166, 166);
    font-size: 1.2rem;
}
.btn{
    margin-top: 1.4rem;
    cursor: pointer;
}



.h1_intro{
    color:#64f4ab;
 font-size: 1.5rem;
}
.center_intro{

    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.h1_large_intro{
    font-size: 4.7rem;
    font-weight: bold;
}
.Btn_intro{
    padding:1rem 3.0rem;
    background-color: #ffaf29;
}
.circle{
    border: 170px solid #64f4ab;
    padding: 127px;
border-radius: 310px;
}

/*new sec*/
.Right_section {
    display: flex;
    justify-content: center;
  }
  
  .image_container {
    position: relative;
    z-index: 1;
  }
  
  .circle {
    position: absolute;
    top: 33%;

    background-position: center;
  }
  
  /* Adjust the padding and border values based on your preference */
  
  /*Animations*/
  .image_container {
    position: relative;
    overflow: hidden;
  }
  
  .image_container img {
    position: relative;
    animation: slideIn 1.2s ease-out;
    animation-fill-mode: forwards;
    transform: translateY(100%);
  }
  
  @keyframes slideIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  /*Animation circle*/
  @keyframes moveIn {
    from {
      transform: translateY(-10%);
    }
    to {
      transform: translateY(10%);
    }
  }
  
  .circle {
    animation: moveIn 1s ease-in-out;
  }
  


  /*Responsive */
  @media (max-width:768px){
    .h1_intro{
      font-size: 20px;
    }
    .h1_large_intro{
      font-size: 3rem;
    }
    br{
      display: none;
    }
    .circle{
      top: 70%;
      margin-left: 46px;
      border: 88px solid #64f4ab;
    padding: 52px;
    border-radius: 310px;
    }
.btn2{
  padding: 1rem 7.9rem;
}
.Left_section{
  margin-top: 35px;
  gap: 0.2rem;
}

.image_container img{
  width: 282px;
  margin-left: 43px;
}
  }
  
  
  
  
