.cir_wrapper{
    color: white;
 
}
.cir_r{
    height: 200px;
    width: 200px;
    border:30px solid red;
    border-radius: 200px;
}
.flexColEnd2 {
    display: flex;
    flex-direction: column;

    align-items: flex-end;
  }



  .moveToLeft {
    animation: moveLeftAnimation 4s linear ;
  }
  
  @keyframes moveLeftAnimation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-30%);
    }
  }
  .cir_r {
    width: 100px;
    height: 100px;
    background-color: red;
        border:30px solid red;
    border-radius: 200px;

  }
  
  