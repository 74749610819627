.h_wrapper{
    color:white;
display:flex;
justify-content: space-around;
}
.h_wrapper2{
    color:white;
    display: flex;
    
}
.btn2{
  cursor: pointer;
}
.jessy_font{
    font-size: 23px;
    font-weight:bold;
}
.h-menu{
    gap:2rem;
    margin-left:4rem;
    font-size:1.2rem;
    display: flex;
    align-items: center;
    margin-top: 2.5rem;
}

.end_nav{
    display: flex;
    justify-content: flex-end;
    align-items: center;
   
    margin-right:2rem;
}

.btn1{
 margin-right:2rem;
 border: none;
 border-radius: 12px;
 background-color:rgb(43, 36, 36);
 align-items: center;
 justify-content: center;
 padding: 0.9rem 1.2rem;
cursor: pointer;
}
.btn1:hover{
    background-color:#fecd1a;;

}


.h-menu a {
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
    text-decoration: none;
    color: white; /* Change this to the desired link color */
    transition: color 0.3s;
  }

  .h-menu a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: white; /* Change this to the desired line color */
    transform: scaleX(0);
    transition: transform 0.3s;
    transform-origin: bottom;
  }

  .h-menu a:first-child::after {
    transform: scaleX(1);
  }

  .h-menu a:hover {
    color: white; /* Change this to the desired link color on hover */
  }

  .h-menu a:hover::after {
    transform: scaleX(1);
  }

/* Animation*/
  @keyframes slideFromLeft {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(10);
    }
  }
  
  .h_wrapper {
    color: white;
    display: flex;
    justify-content: space-between;
    animation: slideFromLeft 1.9s ease-in-out;
  }
  
  .end_nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
    animation: slideFromRight 1.5s ease-in-out;
  }
  .h_wrapper {
    color: white;
    display: flex;
    justify-content: space-between;
    animation: fade-in 0.8s ease-in;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .menu-icon{
    display: none;
}

  @media (max-width:768px){
    .menu-icon{
        display: block;
        margin-top: 29px;
        margin-right: 13px;
    }
    .end_nav_block{
      display: none;
    }
    .size_que_image img{
      width: 100px;
    }
    .h-menu{

        position: absolute;
        color: var(--black2);
        top: 3rem;
        right: 4rem;
        flex-direction: column;
        background: var(--black3);
        font-weight: 500;
        gap: 2rem;
        padding: 3rem;
        border-radius: 10px;
        align-items: flex-start;
        box-shadow: 0px 4px 4px rgba(0,0,0,0.05);
        transition: all 300ms ease-in;
        z-index: 99;
    }
}