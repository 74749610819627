.stats{
color: white;
font-size: 3rem;
display: flex;
gap: 0.2rem;
margin-top: 12%;
padding-bottom: 20px;
}
.stat{
    width: 290px;
    height: 350px;
    background-color: var(--black2);
}

.stat4{
    border-top-right-radius:50px;
    border-bottom-right-radius:50px;

}

.secondary_Text2{
    font-size: 28px;
    color: whitesmoke;
}
.count_font{
    font-size: 80px;
    color: #64f4ab;
}


.stat1{
    border-top-left-radius:50px;
    border-bottom-left-radius:50px;

}


@media (max-width:768px){
   
    .stats{
        color: white;
        font-size: 2rem;
        display: flex;
        gap: 0rem;
        margin-top: 12%;
        padding-bottom: 20px;
        }
        .stat{
            width: 343px !important;
            height: 200px !important;
            background-color: var(--black2);
        }
        
        .stat4{

        
        }
        
        .secondary_Text2{
            font-size: 20px;
            color: whitesmoke;
        }
        .count_font{
            font-size: 45px;
            color: #64f4ab;
        }
        
        
        .stat1{
            border-top-left-radius:0px;
            border-bottom-left-radius:0px;
        
        }

        .stat4{
            border-top-right-radius:0px;
            border-bottom-right-radius:0px;
        
        }
        
        

}