.h_wrapper_skill{
    color: white;
}
.h1_skill{
color: #64f4ab;
font-size: 1.6rem;
}
.heading_skill{
    font-size: 3.2rem;
}
.para_skill{
    font-size: 1.2rem;
}
.btn{
    margin-top: 1.4rem;
    cursor: pointer;
    font-size: 1rem;
}
.Right_section_skill{
justify-content: space-evenly;

}


/*Left section  css*/
.cir_icon{
 height: 100px;   
 width: 100px;
border: 2px solid #64f4ab;
 border-radius: 65px;
 background:var(--black3);
 align-items: center;
 justify-content: center !important;
 align-content: center !important;
}
.cir_icon img{
    margin-top: 27px;
    margin-left: 27px;

}
.heading_left_box{
font-size: 2rem;
font-weight: bold;
align-items: center;
justify-content: center;
align-content: center;
display: flex;
flex-direction: column;
}
.par_left_box{
font-size: 1.3rem;
display: flex;
align-items: center;
justify-content: center;
align-content: center;
}
.left_box_top{

    height: 25.5rem;
    width: 18rem;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background:var(--black2);
    border-radius: 38px;
    box-shadow: 0 4px 6px rgba(100, 244, 171, 0.1);
}
.left_box_top:hover{
background-color: #64f4ab;
cursor: pointer;
box-shadow: 20px 20px 36px rgba(85, 150, 117, 0.5);

}
.Right_box_top {
    height: 25.5rem;
    width: 18rem;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: var(--black2);
    border-radius: 38px;
    margin-top: 4rem;
    box-shadow: 0 4px 6px rgba(100, 244, 171, 0.1);
}


.Right_box_top:hover{
    background-color: #64f4ab;
    cursor: pointer;
    box-shadow: 20px 20px 36px rgba(85, 150, 117, 0.5);

    }
    .Right_box_top:hover {
        
    }
.top_skill{
    gap: 2rem;
}

.bottom_skill{
    gap: 2rem;

}
@media (max-width:768px){

    .reve_direction{
    flex-direction: column-reverse !important;
    }
    .h1_skill{
        color: #64f4ab;
        font-size: 1.5rem;
        }
        .heading_skill{
            font-size: 2rem;
        }
        .para_skill{
            font-size: 1rem !important;
        }
        br{
            display: none;
        }
        .Right_section_skill{
            margin-top: 100px;
        }

        .left_box_top{
            width: 372px;
                height: 309px;
        }
        .Right_box_top{
            width: 372px;
                height: 309px;
                margin-top: 20px;
        }


        .cir_icon{
            height: 100px;   
            width: 100px;
           border: 2px solid #64f4ab;
            border-radius: 65px;
            background:var(--black3);
            align-items: center;
            justify-content: center !important;
            align-content: center !important;
           }
           .cir_icon img{
               margin-top: 27px;
               margin-left: 27px;
           
           }
           .heading_left_box{
           font-size: 1.5rem;
           font-weight: bold;
           align-items: center;
           justify-content: center;
           align-content: center;
           display: flex;
           flex-direction: column;
           }
           .par_left_box{
           font-size: 1rem;
           display: flex;
           align-items: center;
           justify-content: center;
           align-content: center;
           }

           .ui_marg{
            margin-top: 50px;
           }
}