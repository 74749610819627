
.full_container_work{
margin-top:50px ;
}
hr{
    width: 105px;
    height: 3px;
    background-color: #327a56;
    border: none;

}
.H_Work_togather{
    color: white;
    width: 90%;
    display: flex;
    justify-self: center;
    align-items: center;
    border-radius: 50px;
    background: var(--black2);
    padding-top: 40px;
    padding-bottom: 20px;
}
hr{
    font-size: large;
}
.work_heading{
font-size: 3.8rem;
}
.work_para{
    font-size: 1.1rem;
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 20px;
    padding-bottom: 20px;
}


@media (max-width:768px){


    hr{
        width: 105px;
        height: 3px;
        background-color: #327a56;
        border: none;
    
    }
    .H_Work_togather{
        color: white;
        width: 90%;
        display: flex;
        justify-self: center;
        align-items: center;
        border-radius: 50px;
        background: var(--black2);
        padding-top: 40px;
        padding-bottom: 20px;
    }
    hr{
        font-size: large;
    }
    .work_heading{
    font-size: 3.8rem;
    }
    .work_para{
        font-size: 1.1rem;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .btn5{
        font-size: 0.7rem;
    }
    .btn4{
        padding: 1rem 3.9rem;
    }

}


.cent_secree{

    margin-right: 20px;
}