.up_section .h1_up_contact{
    color:#64f4ab;
    font-size: 1.5rem;
   }

.heading_section{
   font-size: 2.4rem;
}

.para_section_cont{
   width: 600px;
   color:rgb(175, 166, 166);
   font-size: 1.1rem;
}
.heading_phone{
   font-size: 24px;
    

}
.number1_contact{
   font-size: 1.2rem;
   color:rgb(175, 166, 166);

}
.number2_contact{
   font-size: 1.2rem;
   color:rgb(175, 166, 166);

}
.up_contact_section{
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 1.3rem;
}

.up2_contact_section{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.3rem;
    margin-left: 80px;
 }

 .phone_icon{
    
    border-radius: 200px;
    height: 90px;
    width: 90px;
    align-items: center;
    display: flex;
    justify-content: center;
    background:var(--black3);
    

 }
 .phone_icon:hover{
    background-color: #fecd1a;
    cursor: pointer;
 }

 /*Right section */
.Right_section{
    margin-top: 0;
}
.nameFirst{
    width: 270px;
    height: 55px;
    background: var(--black3);
    border-radius: 10px;
    border: 1px solid #64f4ab;
}
.nameFirst::placeholder{
color: white;
font-size: 15px;
padding-left: 10px;

}

.nameLast{
    width: 270px;
    height: 55px;
    background: var(--black3);
    border-radius: 10px;
    border: 1px solid #64f4ab;
    margin-left: 10px;
}
.nameLast::placeholder{
color: white;
font-size: 15px;
padding-left: 10px;


}

#myTextarea{
    border:2px solid #64f4ab !important;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--black3);
    margin-top: 20px;

}
#myTextarea::placeholder{
    color: white;
font-size: 15px;
padding: 20px;
}

.btn_contact_me{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 200px;
}

.phonesss{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.pd_bt{
   padding-bottom: 8px;
}

@media (max-width:768px){

.up_section{
   color:#64f4ab;
   font-size: 1.5rem;
   width: 400px;
  }

  .para_section_cont{
   
   color:rgb(175, 166, 166);
   font-size: 1rem;
    width: 369px;

}
.h1_up_contact{
   color:#64f4ab;
   font-size: 1rem;
  }
  .heading_section {
   font-size: 2rem;
}

.phonesss{
   margin-top: 100px;
   gap: 2rem;
}

.up_contact_section{
   display: flex;
   align-items: center;
   gap: 1.3rem;
}

.up2_contact_section{
   display: flex;
   gap: 1.3rem;
   margin-left: 16px;

 }
 .heading_phone {
   font-size: 22px;
}
.number1_contact {
   font-size: 1rem;
   color: rgb(175, 166, 166);
}
.number2_contact {
   font-size: 1rem;
   color: rgb(175, 166, 166);
}
.nameFirst {
   width: 296px;
   height: 61px;
   background: var(--black3);
   border-radius: 10px;
   border: 1px solid #64f4ab;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-left: 33px;
}
.nameLast{
width: 296px;
    height: 61px;
    background: var(--black3);
    border-radius: 10px;
    border: 1px solid #64f4ab;
    margin-left: 33px;
    margin-top: 14px;
}

#myTextarea {
   border: 2px solid #64f4ab !important;
   border-radius: 30px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: var(--black3);
   margin-top: 20px;
   width: 306px;
   margin-left: 28px;
}
.btn_contact_me {
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 20px;
   margin-left: 30px;
}
.container_input{
   margin-top: 40px;

}
}