.footer-container{
    color: white;
    justify-content: space-between;

}
.footer{
    margin-top: 13%;
    padding-top:20px;
    background-color: #25262A;
}
.img_c img{
    width: 140px;
}
.second_container{
    font-size: 20px;
    margin-right: 30px;
}

.con{
    display: flex;
    gap: 2rem;
    margin-left: 30px;
}

.bd{

    padding: 20px;
   background-color: #2D2E32;
    border-radius: 10px;
}

.bd:hover{

    background-color: #fecd1a;
    cursor: pointer;
 color: black !important;
}

.f00t_pd{
    padding-bottom: 60px;

}


@media (max-width:768px){
    .footer-container{
        color: white;
        justify-content: center;
    
    }
    .footer{
        margin-top: 13%;
        padding-top:20px;
        background-color: #25262A;
    }
    .img_c img{
        width: 140px;
    }
    .second_container{
        font-size: 18px;
        padding-left: 46px;
    }
.con{
    margin-top: 20px;
}
.bd{
    padding: 10px;
    background-color: #2D2E32;
    border-radius: 10px;
    margin-left: 16px;
}
}